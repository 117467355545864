<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <h4 class="my-0 text-center"><b>Data Profile</b></h4>
          </CCardHeader>
          <CForm v-on:submit.prevent="storeProcess" class="fwdwd">
            <CCardBody>
              <CRow class="mt-3">
                <div class="col-md-12">
                  <div class="form-group">
                    <b>NIP<span class="text-danger">*</span></b>
                    <input
                      type="text"
                      class="form-control"
                      v-model="nip"
                      readonly=""
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <b>Nama<span class="text-danger">*</span></b>
                    <input type="text" class="form-control" v-model="name" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <b>Email <span class="text-danger">*</span></b>
                    <input type="text" class="form-control" v-model="email" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <b>Phone <span class="text-danger">*</span></b>
                    <input type="text" class="form-control" v-model="phone" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <b>Role <span class="text-danger">*</span></b>
                    {{ userdata.roles.name }}
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <b>Password <span class="text-danger">*</span></b>
                    <input
                      type="password"
                      class="form-control"
                      v-model="password"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <b
                      >Konfirmasi Password <span class="text-danger">*</span></b
                    >
                    <input
                      type="password"
                      class="form-control"
                      v-model="password_confirmation"
                    />
                  </div>
                </div>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <button
                class="btn btn-success text-center btn-block"
                type="submit"
              >
                Simpan
              </button>
              <button
                class="btn btn-info text-center btn-block"
                type="button"
                v-on:click="showModalSync"
              >
                Sinkron akun ke aplikasi Portal Perizinan
              </button>
              <small>*) Isi password untuk edit data anda.</small>
            </CCardFooter>
          </CForm>

          <template>
            <div>
              <CModal
                title="Sinkron"
                color="btn-primary"
                :show.sync="modalSyncAkun"
              >
                <CRow>
                  <CCol md="12">
                    <div class="form-group">
                      <b
                        >Masukan password anda
                        <span class="text-danger">*</span></b
                      >
                      <input
                        type="password"
                        class="form-control"
                        v-model="password_sync"
                      />
                    </div>
                  </CCol>
                </CRow>
                <template #footer>
                  <CButton @click="modalSyncAkun = false" color="secondary"
                    >Tutup</CButton
                  >
                  <CButton v-on:click="syncAkun()" color="primary"
                    >Simpan</CButton
                  >
                </template>
              </CModal>
            </div>
          </template>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
    export default {
        name: "ProfileAdmin",
        data() {
            return {
                nip:'',
                name:'',
                email:'',
                phone:'',
                password:'',
                password_confirmation:'',
                session_data: JSON.parse(this.session),
                userdata: '',
      modalSyncAkun: false,
      password_sync: "",
            }
        },
        methods: {
            storeProcess() {
                Swal.fire({
                    title: '<i class="fa fa-refresh fa-spin"></i>',
                    text: "Loading...",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });
                var FormData = require("form-data");
                var data = new FormData();
                data.append("name", this.name);
                data.append("nip", $("#nip").val());
                data.append("email", this.email);
                data.append("password", this.password);
                data.append("password_confirmation", this.password_confirmation);
                // data.append("nib", $("#nibmember").val());
                // data.append("phone", $("#phone").val());
                // data.append("npwp", $("#npwpmember").val());
                var config = {
                    method: "post",
                    url: this.apiLink + "api/user",
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                    data: data,
                };
                axios(config)
                    .then((response) => {
                        let timerInterval;
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Data Registrasi Anda Berhasil Disimpan",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            didOpen: () => {
                                timerInterval = setInterval(() => {
                                    const content = Swal.getContent();
                                    if (content) {
                                        const b = content.querySelector("b");
                                        if (b) {
                                            b.textContent = Swal.getTimerLeft();
                                        }
                                    }
                                }, 100);
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                // console.log("I was closed by the timer");
                            }
                        });
                    })
                    .catch((error) => {
                        // console.log(error);
                        if (error) {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Terjadi Kesalahan, Coba Lagi Nanti",
                            });
                        }
                    });
            },
    showModalSync() {
      this.modalSyncAkun = true;
      this.password_sync = "";
        },
    syncAkun() {
      let self = this;
      Swal.fire({
        title: '<i class="fa fa-refresh fa-spin"></i>',
        text: "Mohon Tunggu Sebentar.",
        showConfirmButton: false,
      });
      axios
        .post(
          this.apiLink + "api/crud/user/sync-account-verifikator",
          {
            email: self.session_data.email,
            password: self.password_sync,
          },
          {
            headers: {
              Authorization: "Bearer " + this.access_token,
            },
          }
        )
        .then(function (response) {
          var res_data = response.data;
          console.log("sukses : ", res_data);
          Swal.fire({
            icon: "success",
            title: "Sukses.",
            text: "Sinkronisasi akun berhasil!",
          });
          self.modalSyncAkun = false;
        })
        .catch((error) => {
          console.log("error : ", error);
          Swal.fire({
            icon: "error",
            title: "Gagal",
            text: error.response.data.meta.message,
          });
          //   self.modalSyncAkun = false;
        });
    },
  },
  mounted() {},
  created() {
    Swal.fire({
      title: '<i class="fa fa-refresh fa-spin"></i>',
      text: "Loading...",
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    axios
      .get(this.apiLink + "api/crud/user", {
        params: {
          id: this.session_data.id,
        },
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_user = response.data;
        var res_user_data = res_user.data[0].data[0];
        if (res_user.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_user.data.message,
          });
        } else {
          Swal.close();
          this.nip = res_user_data.nip;
          this.name = res_user_data.name;
          this.email = res_user_data.email;
          this.phone = res_user_data.phone;
          this.userdata = res_user_data;
        }
      });
  },
};
</script>